'use client';

/*
 * This hook hides some complexity around tracking page views within
 * our site. We have a combination of traditional pages, and also
 * "infinite" scroll pages (limited to 10 articles per page, not actually
 * infinite). This hook allows you to track a page view in the traditional
 * sense: it fires when clicking from one article to another, but not when
 * scrolling through additional articles on the same page. This is a
 * nuanced distinction, but important for certain purposes.
 */

import { useState, useEffect, useCallback } from 'react';
import { useAdService } from '@/context/AdServiceContext';
import { useEventsByType } from '@/utils/hooks/use-events';
import { type Event, ARENA_PAGE_VIEW } from '@/utils/events';

export type OnPageViewCallback = () => void;

export const useOnPageView = (cb: OnPageViewCallback) => {
	const adService = useAdService();

	// When taking correlator into account, we look first at the "infinite"
	// scroll correlator, and if that's not present, we fall back to the
	// view correlator. The latter changes for every page view, but also
	// when viewing articles in continuous scroll. So to detect when a real
	// page view occurs, we have to watch when the scroll correlator changes
	// first. But if the scroll correlator is not available, a change
	// to the view correlator will trigger as well.
	const [prevCorrelator, setPrevCorrelator] = useState(
		adService.continuousScrollCorrelator || adService.viewCorrelator,
	);
	const [correlator, setCorrelator] = useState(
		adService.continuousScrollCorrelator || adService.viewCorrelator,
	);

	useEffect(() => {
		// There was a change for the monitored correlator; trigger the callback
		// and update the previous value.
		if (prevCorrelator !== correlator) {
			cb();
			setPrevCorrelator(correlator);
		}
	}, [cb, correlator, prevCorrelator]);

	// Track changes to the continuous scroll correlator via a subscription to
	// the USER_ARTICLE_VIEW event, which fires upon navigation to a new article
	// as well as scrolling through additional articles.
	const onPageView = useCallback(
		(e: Event) => {
			setCorrelator(
				adService.continuousScrollCorrelator || adService.viewCorrelator,
			);
		},
		[adService],
	);

	useEventsByType(ARENA_PAGE_VIEW, onPageView);
};
