'use client';

import clsx from 'clsx';
import { Zones } from '@/types/ads';
import { AdSlot } from '@/components/AdSlot';
import Close from '@themaven-net/raven-assets/Close';
import { useOnPageView } from '@/utils/hooks/use-on-page-view';
import { useRef, useState, useEffect, useCallback } from 'react';

import styles from './styles.module.css';

// Updates CSS variable to allow for some additional padding for footer that
// allows for the maximum height of the footer ad. This prevents the ad from
// covering site copyright, etc.
const updateFooterAdHeight = (size: number) => {
	document.documentElement.style.setProperty('--footer-ad-height', `${size}px`);
};

export const FooterAdSlot = () => {
	const defaultRefreshTimer = 30; // TODO: make this configurable in the CMS
	const footerAdSlotRef = useRef<HTMLDivElement>(null);
	const [isShown, setIsShown] = useState(false);
	const [isClosed, setIsClosed] = useState(false);
	const [refresh, setRefresh] = useState<number | undefined>(
		defaultRefreshTimer,
	);

	const handleCloseClick = () => {
		setIsClosed(true);
		setIsShown(false);
		setRefresh(undefined);
	};

	useOnPageView(() => {
		setRefresh(defaultRefreshTimer);
		setIsClosed(false);
	});

	// FIXME: when an ad request fails to deliver, it would be nice if we could
	// preserve the ad that was already rendered instead of emptying the slot.
	// I'm not sure how to fix this, since it seems to be GPT's behavior.
	const onRender = useCallback(
		(e: googletag.events.SlotRenderEndedEvent) => {
			if (isClosed) return;
			const show = !e.isEmpty;
			setIsShown(show);
		},
		[isClosed],
	);

	// Synchronize height of footer ad with CSS variable, which is used to
	// position other page elements.
	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				let newHeight;
				// A degree of backward compatibility for older browsers that
				// don't support borderBoxSize.
				if (entry.borderBoxSize) {
					newHeight = entry.borderBoxSize[0].blockSize;
				} else {
					newHeight = entry.contentRect.height;
				}
				updateFooterAdHeight(isClosed ? 0 : newHeight);
			});
		});
		if (footerAdSlotRef.current) {
			resizeObserver.observe(footerAdSlotRef.current);
		}

		return () => resizeObserver.disconnect();
	}, [footerAdSlotRef, isClosed]);

	const className = clsx(styles.footerAdSlot, {
		[styles.isShown]: isShown,
	});

	return (
		<div className={className} ref={footerAdSlotRef}>
			<button
				aria-label="Close ad"
				className={styles.closeButton}
				onClick={handleCloseClick}
				type="button"
			>
				<Close />
			</button>
			<AdSlot onRender={onRender} refresh={refresh} zone={Zones.fixed_bottom} />
		</div>
	);
};
