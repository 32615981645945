'use client';

import clsx from 'clsx';
import type { FC } from 'react';
import { useState } from 'react';
import { Menu } from '@/components/raven/Menu';
import { useEventsByType } from '@/utils/hooks/use-events';
import { PrivacySettings } from '@/components/PrivacySettings';
import type { FooterProps } from '@/components/raven/global/Footer';
import { ARTICLE_FETCH, ARTICLE_FETCH_COMPLETE } from '@/utils/events';

import styles from './styles.module.css';

export const SimpleFooter: FC<FooterProps> = ({ copyright, menus }) => {
	const copyrightParsed = copyright
		? copyright.replace(/\[year\]/g, new Date().getFullYear().toString())
		: '';
	const [fetching, setFetching] = useState(false);
	useEventsByType(ARTICLE_FETCH, () => setFetching(true));
	useEventsByType(ARTICLE_FETCH_COMPLETE, () => setFetching(false));
	return (
		!fetching && (
			<footer className={clsx(styles.siteFooter, 'is-dark')} id="main-footer">
				<Menu
					ariaLabel="Footer Menu"
					className={styles.siteFooterList}
					linkClassName={styles.siteFooterListItemLink}
					links={menus.legal}
					listItemClassName={styles.siteFooterListItem}
					privacySettings={PrivacySettings}
				/>

				{copyright && (
					<p className={styles.siteFooterCopyright}>&copy; {copyrightParsed}</p>
				)}
			</footer>
		)
	);
};
