'use client';

import type { Article } from '@/components/ArticleList/types';
import { type FC, useContext, createContext, type ReactNode } from 'react';

const ArticleContext = createContext<Article | undefined>(undefined);

export const ArticleProvider: FC<{
	article: Article;
	children: ReactNode;
}> = ({ article, children }) => (
	<ArticleContext.Provider value={article}>{children}</ArticleContext.Provider>
);

export const useArticle = () => {
	return useContext(ArticleContext);
};
