'use client';

import clsx from 'clsx';
import { makeAdUnitPath } from '@/utils/ads';
import { usePathname } from 'next/navigation';
import type { Breakpoint } from 'tempest-common';
import { useMemo, useState, useEffect } from 'react';
import { useRef, type FC, useCallback } from 'react';
import { useArticle } from '@/context/ArticleContext';
import { useSiteContext } from '@/context/SiteContext';
import { useAdService } from '@/context/AdServiceContext';
import type { Size, Zone, AdSlotModel } from '@/types/ads';
import { useBreakpoint } from '@/utils/hooks/use-breakpoint';
import { IN_CONTENT_BANNER_HEIGHTS } from '@/utils/ads/zones';
import { useIsAdInView } from '@/utils/hooks/use-is-ad-in-view';

import styles from './styles.module.css';

type RenderFunc = (event: googletag.events.SlotRenderEndedEvent) => void;
type Timeout = ReturnType<typeof setTimeout>;

interface AdSlotProps {
	breakpoint?: Breakpoint;
	id?: string;
	index?: number;
	onRender?: RenderFunc;
	refresh?: number;
	reserveHeight?: boolean;
	sizes?: Size[];
	zone?: Zone;
}

/**
 * AdSlot component
 * Client-side React component responsible for rendering the ad slot for a Google ad tag.
 * @param {string} breakpoint - The breakpoint for the ad slot (A, B, C, D) if it is restricted to a specific breakpoint.
 * @param {string} id - The unique identifier for the ad slot.
 * @param {number} index - The index for the ad slot.
 * @param {RenderFunc} onRender - The callback function to execute when the ad slot is rendered.
 * @param {number} refresh - The refresh rate for the ad slot (in seconds).
 * @param {boolean} reserveHeight - Whether to reserve height for the ad slot.
 * @param {Array} sizes - The sizes for the ad slot (optional; standard sizes are determined by zone).
 * @param {string} zone - The zone name for the ad slot.
 * @returns {JSX.Element} - The ad slot element.
 */
export const AdSlot: FC<AdSlotProps> = ({
	breakpoint,
	id: slotId,
	index = 0,
	onRender,
	refresh,
	reserveHeight,
	sizes,
	zone,
}) => {
	const clientBreakpoint = useBreakpoint();
	const [isClient, setIsClient] = useState(false);
	const [isShown, setIsShown] = useState(true);
	const adService = useAdService();
	const browserPath = usePathname();
	const { config: siteConfig } = useSiteContext();
	const slotRef = useRef<HTMLDivElement>(null);
	const clampSize = useRef<[number, number] | null>(null);
	const { addElement, isInView, removeElement } = useIsAdInView();
	const [localInView, setLocalInView] = useState(isInView);
	const [hasRendered, setHasRendered] = useState(false);
	const refreshTimer = useRef<null | Timeout>(null);

	// The behavior of removing ad slots as they are scrolled out of view was
	// created to address memory issues on iPhone devices. We'll limit this
	// feature to those user agents for now.
	const useInViewFilter = isClient && navigator.userAgent.includes('iPhone OS');

	// There's a better place to do this, but doing it here for now
	adService.setPageTargeting({ path: browserPath });

	let thisSlotId = slotId;
	if (!thisSlotId) {
		// if no id is provided, make one using zone, index; this
		// works for static ad slots (fixed bottom, or adhesion), but
		// not for in content ads, since there can be more than one
		// in-content index 0 ad on a page with multiple articles.
		thisSlotId = `ad-${zone}-${index}`;
	} else {
		// add zone and index to this generated id
		thisSlotId = `${thisSlotId}-${zone}-${index}`;
	}

	// We don't always have an article in context; if none is available, then use the browser path.
	const article = useArticle();
	const path = makeAdUnitPath(article, browserPath, siteConfig);

	// Memoizing the slot model since it is used in the useEffect hook below. Otherwise,
	// the effect would run on each render, even if the model properties have not changed.
	const slotModel = useMemo(
		() => ({
			adUnitPath: path,
			breakpoint: clientBreakpoint,
			index,
			refresh,
			sizes,
			slotId: thisSlotId,
			zone,
		}),
		// Note: We are intentionally excluding 'path' from the dependencies array, so
		// the value gets 'baked' into the model when initially rendering the component.
		// As the user scrolls through a page, the path can update, but the ad slot
		// should use the initial path when it was first rendered.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[clientBreakpoint, index, refresh, sizes, thisSlotId, zone],
	) as AdSlotModel;

	const renderCallback = useCallback(
		(e: googletag.events.SlotRenderEndedEvent) => {
			let retryRefreshRate = 60;

			// TBD: if a fluid ad is rendered, should we do anything special
			// for future resize? The fluid ad format supports resize, so
			// it becomes usable for all breakpoints.

			if (!e.isEmpty) {
				setHasRendered(true);

				if (
					slotModel.zone === 'in_content' &&
					!clampSize.current &&
					e.size &&
					Array.isArray(e.size) &&
					e.size.length === 2 &&
					IN_CONTENT_BANNER_HEIGHTS.includes(e.size[1])
				) {
					// At the next opportunity, redefine the slot and restrict
					// new requests to this size to prevent layout shift.

					// This behavior is also limited to specific sizes that
					// are appropriate for in-content ads where layout shift
					// is a concern. Fluid ads can any height, and we shouldn't
					// clamp to an unusual dimension since it would prevent
					// further delivery.
					clampSize.current = [e.size[0], e.size[1]];
				}

				if (slotRef.current) {
					const sr = slotRef.current;
					requestAnimationFrame(() => {
						// Clear any fixed height assignment if it was set
						// previously when unloading the slot.
						sr.style.height = '';
					});
				}
			}

			if (onRender) {
				onRender(e);
			} else if (e.isEmpty) {
				// only do this if no onRender callback is provided;
				// since we expect the parent to handle it.
				if (!reserveHeight) {
					setIsShown(false);
				}
				retryRefreshRate = 5;
			}

			// Exclude interstitial zones from ad retry behavior
			const retry = !slotModel.zone?.includes('interstitial');

			if ((e.isEmpty && retry) || slotModel.refresh) {
				// Other considerations for ad refresh behavior:
				// - do not refresh if the ad is not in view
				// - decrease refresh rate if the there have been no interaction
				//   events (scrolling, mouse movement, clicks) for some period.
				//   once interaction events resume, refresh if last was > refresh
				//   rate, and then restore configured refresh rate.
				let refreshRate = slotModel.refresh;
				if (e.isEmpty && retry) {
					refreshRate = retryRefreshRate;
				}
				if (refreshRate) {
					refreshTimer.current = setTimeout(() => {
						refreshTimer.current = null;
						adService.refreshSlot(slotModel.slotId);
						// Clear the rendered flag, which will prevent the slot from being removed from the
						// page (at least until it has rendered and is scrolled out of view).
						setHasRendered(false);
					}, refreshRate * 1000);
				}
			}
		},
		[adService, onRender, reserveHeight, slotModel],
	);

	// In the event that the component was declared to target a specific breakpoint,
	// only render it if it matches with clientBreakpoint.
	const breakpointMismatch =
		(breakpoint && breakpoint !== clientBreakpoint) || !clientBreakpoint;

	// TBD: utilize viewablity to determine if ad is in view. If it is not,
	// prevent refresh behavior. If it nears the viewport, load or refresh
	// the slot.

	// (Re)define ad slot on breakpoint change
	useEffect(() => {
		setIsClient(true);
		let cleanup;

		// We only render the ad if appropriate for the breakpoint and
		// if the post is monetized, so we should only define the slot
		// and refresh, etc. if it is rendered.
		if (adService.showAds && !breakpointMismatch) {
			adService.defineSlot(slotModel, { onRender: renderCallback });

			cleanup = () => {
				if (refreshTimer.current) {
					clearTimeout(refreshTimer.current);
					refreshTimer.current = null;
				}
				adService.destroySlot(slotModel.slotId);
				// If an ad visibility context is available, remove the
				// container (does nothing if addElement never executed).
				removeElement(slotRef.current);
			};
		}
		return cleanup;
	}, [
		adService,
		onRender,
		breakpointMismatch,
		renderCallback,
		reserveHeight,
		slotModel,
		isShown,
		removeElement,
	]);

	// This effect handles viewability refresh. If the slot becomes viewable,
	// refresh the slot.
	useEffect(() => {
		if (isInView) {
			if (clampSize.current) {
				const cs = clampSize.current;
				adService.destroySlot(slotModel.slotId);
				adService.defineSlot(
					{ ...slotModel, sizes: [[cs[0], cs[1]]] },
					{ onRender: renderCallback },
				);
				clampSize.current = null;
			}
			adService.refreshSlot(slotModel.slotId);
			// Clear the rendered flag, which will prevent the slot from being removed from the
			// page (at least until it has rendered and is scrolled out of view).
			setHasRendered(false);
			setLocalInView(true);
		} else {
			// Determine current height of ad slot before we hide it
			const height = slotRef.current?.getBoundingClientRect().height;
			if (height) {
				// Preserve the height of the container if the ad is not viewable;
				// this reduces layout shift while scrolling.
				slotRef.current.style.height = `${height}px`;
			}
			setLocalInView(false);
		}
	}, [adService, slotModel, isInView, renderCallback]);

	const skipRender =
		!isClient || !isShown || breakpointMismatch || !adService.showAds;

	// This effect is responsible for ad viewability
	useEffect(() => {
		if (skipRender || !slotRef.current) {
			return;
		}

		addElement(slotRef.current);
	}, [addElement, skipRender]);

	if (skipRender) {
		return null;
	}

	// In some cases we want to remove the ad slot; this is useful for reclaiming
	// memory when an ad is scrolled out of view. But we want to preserve the slot
	// until a requested ad is served. Also we have a general useInViewFilter const
	// to control this behavior.
	const showAdSlot =
		// if useInViewFilter is false, we always show the ad slot
		!useInViewFilter ||
		// if the slot is in view, it should stay on page
		localInView ||
		// if the slot has not rendered yet, it should stay on page
		!hasRendered;

	let sizeStyle;
	if (reserveHeight) {
		const reservedHeight = adService.getReservedHeight(slotModel);
		// Set min height for the ad slot to the reserved height requested for the slot
		sizeStyle = reservedHeight ? { minHeight: `${reservedHeight}px` } : {};
	}
	const className = clsx(styles.adSlot, styles[`adSlot-${zone}`]);
	return (
		<div className={className} id={`${thisSlotId}-container`} ref={slotRef}>
			{showAdSlot && <div id={thisSlotId} style={sizeStyle} />}
		</div>
	);
};
