import { useState, useContext, useCallback } from 'react';
import { AdVisibilityContext } from '@/context/AdVisibilityContext';
import { useDebouncedCallback } from '@/utils/hooks/use-debounced-callback';

interface UseIsAdInViewReturnType {
	addElement: (element: Element | null) => void;
	isInView: boolean;
	removeElement: (element: Element | null) => void;
}

export const useIsAdInView = (): UseIsAdInViewReturnType => {
	const context = useContext(AdVisibilityContext);
	// This hook is tolerant of the case when AdVisibilityContext
	// is unavailable, since some ad slots are not always within
	// the AdVisibilityProvider (in such a case, isInView is always
	// true).
	const [isInView, setIsInView] = useState<boolean>(!context);

	const debouncedSetIsInView = useDebouncedCallback(
		(inView) => {
			setIsInView(inView as boolean);
		},
		[],
		150,
	);

	const observe = useCallback(
		(element: Element | null) => {
			if (element && context) {
				context.addElement(element, (inView) => {
					debouncedSetIsInView(inView);
				});
			}
		},
		[context, debouncedSetIsInView],
	);

	const unobserve = useCallback(
		(element: Element | null) => {
			if (context && element) {
				context.removeElement(element);
			}
		},
		[context],
	);

	return { addElement: observe, isInView, removeElement: unobserve };
};
