import {
	useRef,
	type FC,
	useMemo,
	useEffect,
	useCallback,
	createContext,
	type PropsWithChildren,
} from 'react';

interface AdVisibilityContextType {
	addElement: (element: Element, callback: (inView: boolean) => void) => void;
	removeElement: (element: Element | null) => void;
}

const AdVisibilityContext = createContext<AdVisibilityContextType | undefined>(
	undefined,
);

const AdVisibilityProvider: FC<PropsWithChildren> = ({ children }) => {
	const observer = useRef<IntersectionObserver | null>(null);
	const elements = useRef<Map<Element, (inView: boolean) => void>>(new Map());

	useEffect(() => {
		observer.current = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					const callback = elements.current.get(entry.target);
					if (callback) {
						callback(entry.isIntersecting);
					}
				});
			},
			{
				rootMargin: '400% 0px 400% 0px',
			},
		);

		return () => {
			observer.current?.disconnect();
		};
	}, []);

	const addElement = useCallback(
		(element: Element | null, callback: (inView: boolean) => void) => {
			if (observer.current && element) {
				observer.current.observe(element);
				elements.current.set(element, callback);
			}
		},
		[],
	);

	const removeElement = useCallback((element: Element | null) => {
		if (observer.current && element) {
			observer.current.unobserve(element);
			elements.current.delete(element);
		}
	}, []);

	return (
		<AdVisibilityContext.Provider
			value={useMemo(
				() => ({ addElement, removeElement }),
				[addElement, removeElement],
			)}
		>
			{children}
		</AdVisibilityContext.Provider>
	);
};

export { AdVisibilityContext, AdVisibilityProvider };
