'use client';

import type { FC } from 'react';
import { AdSlot } from '@/components/AdSlot';
import type { Breakpoint } from 'tempest-common';

type OutOfPageAdSlotProps = {
	breakpoint?: Breakpoint;
	exit?: boolean;
};

const updateHeaderAdHeight = (size: number) => {
	document.documentElement.style.setProperty('--header-ad-height', `${size}px`);
};

export const OutOfPageAdSlot: FC<OutOfPageAdSlotProps> = ({
	breakpoint,
	exit = false,
}) => {
	const onRender = (event: googletag.events.SlotRenderEndedEvent) => {
		const show = !event.isEmpty;
		let adHeight =
			show && Array.isArray(event.size) && event.size.length === 2
				? event.size[1]
				: 0;

		if (adHeight === 1) {
			const el = document.getElementById(event.slot.getSlotElementId());

			if (!el) {
				return;
			}

			const iframe = el.querySelector('iframe');
			if (iframe) {
				const loadCallback = () => {
					iframe.removeEventListener('load', loadCallback);
					adHeight = el?.clientHeight ?? 0;
					updateHeaderAdHeight(adHeight);
				};
				iframe.addEventListener('load', loadCallback);
			}
		} else {
			updateHeaderAdHeight(adHeight);
		}
	};

	return (
		<AdSlot
			breakpoint={breakpoint}
			onRender={onRender}
			zone={`${exit ? 'exit_' : ''}interstitial`}
		/>
	);
};
